@import '~react-table/react-table.css';

@font-face {
  font-family: 'BebasNeue';
  src: url('./fonts/BebasNeue.otf') format('opentype');
}

@font-face {
  font-family: 'RalewayRegular';
  src: url('./fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RalewayMedium';
  src: url('./fonts/Raleway-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'RalewayLight';
  src: url('./fonts/Raleway-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'RalewaySemiBold';
  src: url('./fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirDemi';
  src: url('./fonts/AvenirNextLTProDemi.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirMedium';
  src: url('./fonts/Avenir-Medium.ttf') format('truetype');
}

body {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  /* min-width: 1000px; */
}

body,
div,
section {
  box-sizing: border-box;
}

hr {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
